<template>
  <label class="form-field block">
    <span
      v-if="fieldLabel"
      class="label block"
    >
      {{fieldLabel}}
    </span>

    <multiselect
      v-model="localSelectedOptions"
      ref="multiSelect"
      openDirection="bottom"
      selectLabel=""
      deselectLabel=""
      :label="label"
      :track-by="trackBy"
      :multiple="true"
      :taggable="true"
      :options="options"
      :close-on-select="false"
      :clear-on-select="false"
      @input="$emit('input', localSelectedOptions)"
      @select="$emit('select', $event)"
      @remove="$emit('remove', $event)"
      @close="closeEventHandler"
    >
      <span slot="caret"></span>
    </multiselect>
  </label>
</template>

<script>
import Multiselect from 'vue-multiselect'
import FormFieldMixin from '@/mixins/FormFieldMixin';

export default {
  name: 'FormFieldMultiSelect',

  components: {
    Multiselect
  },

  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: 'title'
    },
    trackBy: {
      type: String,
      default: 'name'
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },

  mixins: [
    FormFieldMixin
  ],

  data() {
    return {
      localSelectedOptions: [],
    }
  },

  mounted() {
    this.$refs.multiSelect.isOpen = true
  },

  methods: {
    closeEventHandler() {
      this.$refs.multiSelect.isOpen = true
    },

    onSelect(val) {
      this.$emit('select', val)
    },

    onRemove(val) {
      this.$emit('remove', val)
    }
  },

  computed: {

  },
  watch: {
    value: {
      handler(options) {
        this.localSelectedOptions = [...options]
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

>>> .multiselect--active {
  @apply z-0;
}

>>> .multiselect__content-wrapper {
  @apply static;
}

>>> .multiselect__tags,
>>> .multiselect__content-wrapper{
  @apply border-2;
  border-color: var(--color-medium);
}

>>> .multiselect__tags {
  @apply border-b pl-3 md:pl-5 xl:pl-7;
  border-bottom-color: #9C9C9C;

}

>>> .multiselect__tags .multiselect__input {
  @apply pl-0;
}

>>> .multiselect__content .multiselect__option {
  @apply px-3 md:px-5 xl:px-7
}

>>> .multiselect__element .multiselect__option:after {
  @apply pr-3 md:pr-5 xl:pr-7
}

>>> .multiselect__content-wrapper {
  @apply border-t-0
}

>>> .multiselect__tag,
>>> .multiselect__option--highlight{
  background-color: var(--color-medium);
}

>>> .multiselect__option--highlight:hover {
  background-color: var(--color-medium);
}

>>> .multiselect__tag-icon:after {
  @apply text-white
}

>>> .multiselect__tag-icon:hover {
  background: inherit;
}

</style>
