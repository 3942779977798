<template>
  <div>
    <form-field-input
      v-model="localDiet.name"
      class="mb-8"
      field-label="Feed Mill Name"
      placeholder="New Feed Mill"
      size="large"
      @input="$emit('input', localDiet)"
    />

    <!--NOTE: value - current values from feed, options - all ingredients-->
    <form-field-multi-select
      v-model="localDiet.allIngredients"
      field-label="Select all the ingredients to be included in the diet"
      :options="ingredientsWithoutMandatory"
      :label="label"
      :track-by="trackBy"
      @select="$emit('select', $event)"
      @remove="$emit('remove', $event)"
      @input="$emit('input', localDiet)"
    />

    <div class="buttons flex justify-between mt-8">
      <slot></slot>

      <button
        v-if="deleteButton"
        class="btn-danger"
        @click="$emit('delete', localDiet)"
      >
        <span class="icon" v-html="icons.trash" />

        Delete Feed Mill
      </button>

      <button
        class="btn-success"
        @click="$emit('save', localDiet)"
      >
        {{ saveButtonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import FormFieldMultiSelect from '@/components/form/FormFieldMultiSelect';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'FeedEdit',

  props: {
    label: {
      type: String,
      default: 'title'
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    saveButtonTitle: {
      type: String,
      default: 'Save'
    },
    value: {
      type: Object,
      default: () => ({})
    },
    deleteButton: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    localDiet: {}
  }),

  components: {
    FormFieldInput,
    FormFieldMultiSelect
  },

  methods: {
    ...mapMutations({
      addDiet: 'feed/addDiet',
      deleteDiet: 'feed/deleteDiet',
    }),
    //
    // onRemoveIngredient(val) {
    //   this.localDiet.diet_phases.map(phase => {
    //     phase.ingredients = phase.ingredients.filter(ingredient => {
    //       return ingredient.id !== val.id
    //     })
    //   })
    // }
  },

  computed: {
    ...mapGetters({
      ingredientsWithoutMandatory: 'feed/getIngredientsWithoutMandatory'
    }),
  },

  watch: {
    'value': {
      handler(diet) {
        this.localDiet = Object.assign({}, diet)
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>

</style>
